import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Badge,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

function EstadoCuenta() {
  const { user } = useContext(AuthContext);

  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;


  function excelAnalisis() {

    axios
    .get(`${URL_ESTADO_CUENTA}AnalisisClientes`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
     let movs = response.data

     const dataExcel = movs.map((a) => {
        return {
          IDCliente: a.idCliente,
          Cliente: a.cliente,
          Cargos: a.cargo,
          Abonos: a.abono,
          Saldo: a.saldo,
          SaldoNota: a.saldoNota,
          CargosCliente: a.cargoCliente,
          AbonosCliente: a.abonoCliente,
          SaldoCliente: a.saldoCliente,
          DifCargos: a.cargo - a.cargoCliente,
          DifAbonos: a.abono - a.abonoCliente,
          DifSaldo: a.saldo - a.saldoCliente
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "Analisis";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "Analisis",
        sheetFilter: [
          "IDCliente",
          "Cliente",
          "Cargos",
          "Abonos",
          "Saldo",
          "SaldoNota",
          "CargosCliente",
          "AbonosCliente",
          "SaldoCliente",
          "DifCargos",
          "DifAbonos",
          "DifSaldo",
        ],
        sheetHeader: [
          "IDCliente",
          "Cliente",
          "Cargos",
          "Abonos",
          "Saldo",
          "SaldoNota",
          "CargosCliente",
          "AbonosCliente",
          "SaldoCliente",
          "DifCargos",
          "DifAbonos",
          "DifSaldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();

    })
    .catch((err) => {
      console.log(err);
    });

  }

  function excelAnalisisContenedor() {

    axios
    .get(`${URL_ESTADO_CUENTA}AnalisisContenedor`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
     let movs = response.data

     const dataExcel = movs.map((a) => {
        return {
          IDContenedor: a.idContenedor,
          Contenedor: a.contenedor,
          Cargos: a.cargos,
          Gastos: a.gastos,
          CargosContenedor: a.cargosContenedor,
          GastosContenedor: a.gastosContenedor,
          DifCargos: a.cargos - a.cargosContenedor,
          DifGastos: a.gastos - a.gastosContenedor,
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "Analisis";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "Analisis",
        sheetFilter: [
          "IDContenedor",
          "Contenedor",
          "Cargos",
          "Gastos",
          "CargosContenedor",
          "GastosContenedor",
          "DifCargos",
          "DifGastos",
        ],
        sheetHeader: [
          "IDContenedor",
          "Contenedor",
          "Cargos",
          "Gastos",
          "CargosContenedor",
          "GastosContenedor",
          "DifCargos",
          "DifGastos",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();

    })
    .catch((err) => {
      console.log(err);
    });

  }


  function excelAnalisisNotas() {

    axios
    .get(`${URL_ESTADO_CUENTA}Analisis2`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
     let movs = response.data

     const dataExcel = movs.map((a) => {
      if(a.saldo != a.saldoNota){
        return {
          IDVenta: a.idVenta,
          Cargos: a.cargo,
          Abonos: a.abono,
          Saldo: a.saldo,
          SaldoNota: a.saldoNota,
        }
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "Analisis";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "Analisis",
        sheetFilter: [
          "IDVenta",
          "Cargos",
          "Abonos",
          "Saldo",
          "SaldoNota",
        ],
        sheetHeader: [
          "IDVenta",
          "Cargos",
          "Abonos",
          "Saldo",
          "SaldoNota",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();

    })
    .catch((err) => {
      console.log(err);
    });

  }

  function excelAnalisisProveedores() {

    axios
    .get(`${URL_ESTADO_CUENTA}AnalisisProveedores`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
     let movs = response.data

     const dataExcel = movs.map((a) => {
        return {
          IDProveedor: a.idProveedor,
          Proveedor: a.proveedor,
          Cargos: a.cargo,
          Abonos: a.abono,
          Saldo: a.saldo,
          CargosProveedor: a.cargoProveedor,
          AbonosProveedor: a.abonoProveedor,
          SaldoProveedor: a.saldoProveedor,
          DifCargos: a.cargo - a.cargoProveedor,
          DifAbonos: a.abono - a.abonoProveedor,
          DifSaldo: a.saldo - a.saldoProveedor
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "Analisis";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "Analisis",
        sheetFilter: [
          "IDProveedor",
          "Proveedor",
          "Cargos",
          "Abonos",
          "Saldo",
          "CargosProveedor",
          "AbonosProveedor",
          "SaldoProveedor",
          "DifCargos",
          "DifAbonos",
          "DifSaldo",
        ],
        sheetHeader: [
          "IDProveedor",
          "Proveedor",
          "Cargos",
          "Abonos",
          "Saldo",
          "CargosProveedor",
          "AbonosProveedor",
          "SaldoProveedor",
          "DifCargos",
          "DifAbonos",
          "DifSaldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();

    })
    .catch((err) => {
      console.log(err);
    });

  }

  function excelAnalisisBancos() {

    axios
    .get(`${URL_ESTADO_CUENTA}AnalisisBancos`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
     let movs = response.data

     const dataExcel = movs.map((a) => {
        return {
          IDBanco: a.idBanco,
          Banco: a.banco,
          Cargos: a.cargo,
          Abonos: a.abono,
          Saldo: a.saldo,
          CargosBanco: a.cargoBanco,
          AbonosBanco: a.abonoBanco,
          SaldoBanco: a.saldoBanco,
          DifCargos: a.cargo - a.cargoBanco,
          DifAbonos: a.abono - a.abonoBanco,
          DifSaldo: a.saldo - a.saldoBanco
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "Analisis";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "Analisis",
        sheetFilter: [
          "IDBanco",
          "Banco",
          "Cargos",
          "Abonos",
          "Saldo",
          "CargosBanco",
          "AbonosBanco",
          "SaldoBanco",
          "DifCargos",
          "DifAbonos",
          "DifSaldo",
        ],
        sheetHeader: [
          "IDBanco",
          "Banco",
          "Cargos",
          "Abonos",
          "Saldo",
          "CargosBanco",
          "AbonosBanco",
          "SaldoBanco",
          "DifCargos",
          "DifAbonos",
          "DifSaldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();

    })
    .catch((err) => {
      console.log(err);
    });

  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.estadoCuentaClientes ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/Menu"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">

                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelAnalisis}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelAnalisisProveedores}
                >
                  Excel Proveedores
                </Button>

                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelAnalisisBancos}
                >
                  Excel Bancos
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelAnalisisContenedor}
                >
                  Excel Contenedores
                </Button>

                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelAnalisisNotas}
                >
                  Excel Notas <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          <h3 align="center">Analisis Clientes</h3>
         


        </div>
      ) : undefined}
      <br />
    </>
  );
}

export default EstadoCuenta;

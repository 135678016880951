import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function CargosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CARGOS = process.env.REACT_APP_URL_CARGOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_CONCEPTOS_GASTOS = process.env.REACT_APP_URL_CONCEPTOS_GASTOS;
  const [t, i18n] = useTranslation("global");
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [fecha, setFecha] = useState(hoy);
  const [totalUSD, setTotalUSD] = useState(0);
  const [tipoCambio, setTipoCambio] = useState(1);
  const [total, setTotal] = useState(0);
  const [descripcion, setDescripcion] = useState("NA");
  const [conceptosGastos, setConceptosGastos] = useState([]);
  const [selectedConcepto, setSelectedConcepto] = useState("");
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [moneda, setMoneda] = useState("MXN");
  const [comisionPorcentaje, setComisionPorcentaje] = useState(0);
  const [comisionNumero, setComisionNumero] = useState(0);

  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_CONCEPTOS_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });


      axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    }, []);

  const calcula = (e) => {
    setTotalUSD(e);
    setTotal(parseFloat(e) * parseFloat(tipoCambio));
    setComisionNumero((parseFloat(e) * parseFloat(tipoCambio))*(comisionPorcentaje/100))
  };

  const calculaTipoCambio = (e) => {
    setTipoCambio(e);
    setTotal(parseFloat(totalUSD) * parseFloat(e));
    setComisionNumero((parseFloat(totalUSD) * parseFloat(e))*(comisionPorcentaje/100))
  };

  const saveCargo = (event) => {
    event.preventDefault();

    if(selectedCliente == "" || selectedConcepto == "" || total == 0){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        text: "Favor de completar todos los campos",
      });
      return;
    }
    setValidaBoton(false);
        axios.post(
          URL_CARGOS,
          {
            fecha,
            totalUSD,
            tipoCambio,
            total,
            abonos:0,
            saldo:parseFloat(total) + parseFloat(comisionNumero),
            descripcion,
            conceptosGastos:selectedConcepto,
            clientes: selectedCliente,
            moneda,
            comisionPorcentaje,
            comisionNumero
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Cargo",
            detalle: `Fecha: ${fecha} / Concepto: ${selectedConcepto} / Total: ${total} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      });
  };


  return (
    <>
      <Header />
      <br />
      <br />
      {user.cargosCreate ? (
        <div className="container">
          <div className="card container col-sm-8">
            <h3 align="center">{t("Nuevo Cargo")}</h3>
            <Form onSubmit={saveCargo}>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Row>

                <Col md={6}>
                    <Label className="mr-sm-2">
                      {t("Fecha")}
                    </Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fecha}
                      required
                      onChange={(e) => {
                        setFecha(e.target.value);
                      }}
                    />
                  </Col>
                 

                      <Col md={6}>
                    <Label className="mr-sm-2">Cliente</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={selectedCliente}
                        onChange={(e) => setSelectedCliente(e.target.value)}
                      >
                        <option value="0">{t("Selecciona")}</option>
                        {clientes
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return <option value={a._id}>{a.nombre_comercial}</option>;
                          })}
                      </Input>
                      </Col>

                    
                <Col md={6}>
                    <Label className="mr-sm-2">{t("Concepto")}</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={selectedConcepto}
                        onChange={(e) => setSelectedConcepto(e.target.value)}
                      >
                        <option value="0">{t("Selecciona")}</option>
                        {conceptosGastos
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                      </Col>

               
                  <Col md={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      {t("Descripcion")}
                    </Label>
                    <Input
                      type="text"
                      placeholder="Descripcion"
                      value={descripcion}
                      required
                      onChange={(e) => {
                        setDescripcion(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                <Col md={6}>
                    <Label className="mr-sm-2">{t("Moneda")}</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={moneda}
                        onChange={(e) => {
                          setMoneda(e.target.value)
                        setTotalUSD(0)
                        setTotal(0)
                        setTipoCambio(1)
                        setComisionPorcentaje(0)
                        setComisionNumero(0)  
                      }}
                      >
                        <option value="MXN">{t("MXN")}</option>
                        <option value="USD">{t("USD")}</option>
                      </Input>
                      </Col>
                      </Row>
                      {moneda === "USD" ? (
                        
                        <Row>
                        <Col sm={4}>
                          <Label for="exampleEmail" className="mr-sm-2">
                            {t("Total USD")}
                          </Label>
                          <Input
                            type="number"
                            min="0"
                            step="any"
                            placeholder="TotalUSD"
                            value={totalUSD}
                            required
                            onChange={(e) => calcula(e.target.value)}
                          />
                        </Col>
                        <Col sm={4}>
                          <Label for="exampleEmail" className="mr-sm-2">
                            Tipo de Cambio
                          </Label>
                          <Input
                            type="number"
                            min="0"
                            step="any"
                            placeholder="TipoCambio"
                            value={tipoCambio}
                            required
                            onChange={(e) => calculaTipoCambio(e.target.value)}
                          />
                        </Col>
                        <Col sm={4}>
                          <Label for="exampleEmail" className="mr-sm-2">
                            {t("Total MXN")}
                          </Label>
                          <Input
                            type="number"
                            min="0"
                            step="any"
                            value={total}
                            disabled
                            // onChange={(e) => calculaMXN(e.target.value)}
                          />
                        </Col>
                      </Row>
                      ): (
                        <>
                        <Row>
                        <Col sm={4}>
                          <Label for="exampleEmail" className="mr-sm-2">
                            {t("Total MXN")}
                          </Label>
                          {/* <Input
                            type="number"
                            min="0"
                            step="any"
                            value={total}
                            onChange={(e) => {
                              setTotal(e.target.value)
                              setTotalUSD(e.target.value/tipoCambio)
                              setComisionNumero(e.target.value*(comisionPorcentaje/100))
                               }}
                          /> */}

                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={total}
                      className={`form-control`}
                      onValueChange={(value, name) => {
                        setTotal(value)
                        setTotalUSD(value/tipoCambio)
                        setComisionNumero(value*(comisionPorcentaje/100))
                      }}
                    />
                        </Col>
                      </Row>
                     
                      </>
                      )}
                       <Row>
                      <Col sm={4}>
                          <Label for="exampleEmail" className="mr-sm-2">
                            {t("Comisión %")}
                          </Label>
                          <Input
                            type="number"
                            min="0"
                            step="any"
                            value={comisionPorcentaje}
                            onChange={(e) => {
                              setComisionPorcentaje(e.target.value)
                                setComisionNumero(total*(e.target.value/100))
                              }}
                          />
                        </Col>
                        <Col sm={4}>
                          <Label for="exampleEmail" className="mr-sm-2">
                            {t("Comisión $")}
                          </Label>
                          <Input
                            type="number"
                            min="0"
                            step="any"
                            value={comisionNumero}
                            onChange={(e) => {
                              setComisionNumero(e.target.value)
                                setComisionPorcentaje((e.target.value/total*100).toFixed(2))
                              }}
                          />
                        </Col>
                      </Row>
              </FormGroup>
              <br />
              <Col>
                <Row>
                  {validaBoton ? (
                    <Button type="submit" className="btn btn-success">
                      {t("Guardar")}
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-success" disabled>
                      {t("Guardar")}
                    </Button>
                  )}
                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                  <Button
                    href="/Cargos"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                     {t("Regresar")}
                  </Button>
                </Row>
              </Col>
            </Form>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default CargosCreate;

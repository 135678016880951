import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function RetirosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SOCIOS = process.env.REACT_APP_URL_SOCIOS;
  const URL_RETIROS = process.env.REACT_APP_URL_RETIROS;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;
  const [t, i18n] = useTranslation("global");
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [socios, setSocios] = useState([]);
  const [fecha, setFecha] = useState(hoy);

  const [selectedBanco, setSelectedBanco] = useState("");
  const [selectedSocio, setSelectedSocio] = useState("");
  const [bancos, setBancos] = useState([]);
  const [moneda, setMoneda] = useState("");

  const [observaciones, setObservaciones] = useState("NA");

  const [validaBoton, setValidaBoton] = useState(true);
  const [total, setTotal] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useMemo(() => {
    axios
      .get(URL_SOCIOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSocios = response.data;
        setSocios(allSocios);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function saveRetiro() {
    if (selectedBanco == "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debe seleccionar un Banco",
      });
      return;
    }

    Swal.fire({
      title: "Estas seguro?",
      text: "Se registrará el retiro",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Retirar!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();

        axios
          .post(
            URL_RETIROS,
            {
              fecha,
              total,
              observaciones,
              bancos: selectedBanco,
              socios: selectedSocio,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((dataRetiro) => {

            axios.post(
              `${URL_MOVIMIENTOS_BANCOS}`,
              {
                fecha,
                importe: -total,
                tipo: "Egreso",
                bancos: selectedBanco,
                retiros: dataRetiro.data._id,
                moneda,
                tipoCambio: 1,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            )
            .then(() => {

            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }).catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              // footer: `${error.response.data}`,
            });
            console.log(error);
          })

          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              // footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function jalaBanco(e) {
    setSelectedBanco(e);
    bancos.map((a) => {
      if (a._id == e) {
        return setMoneda(a.moneda);
      }
    });
  }




  return (
    <>
      <Header />
      <br />
      <br />
      {user.retirosCreate ? (
        <div className="card container col-sm-11">
          <h3 align="center">{t("Captura un Retiro")}</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={2}>
                  <Label>{t("Fecha")}</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>{t("Importe")}</Label>
          
                  <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={total}
                      className={`form-control`}
                      onValueChange={(value, name) => {
                        setTotal(value);                  
                      }}
                    />
                </Col>
                </Row>
                <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Socio</Label>
                  <Input
                    type="select"
                    value={selectedSocio}
                    onChange={(e) => {
                      setSelectedSocio(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {socios
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.name}
                            </option>
                          );
                      })}
                  </Input>
                </Col>

                <Col md={2}>
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    type="select"
                    value={selectedBanco}
                    onChange={(e) => {
                      jalaBanco(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Banco</option>
                    {bancos
                      .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                      .map((a) => {
                        if (a.moneda == "MXN") {
                          return (
                            <option value={a._id}>
                              {a.banco} {a.cuenta}
                            </option>
                          );
                        }
                      })}
                  </Input>
                </Col>

              
              </Row>

              <Row>
              <Col md={4}>
                  <Label>{t("Observaciones")}</Label>
                  <Input
                    type="text"
                    value={observaciones}
                    required
                    onChange={(e) => {
                      setObservaciones(e.target.value);
                    }}
                  />
                </Col>
              </Row>

              <br />
               
            </FormGroup>
            <br />
            <Row>
              <Button className="btn btn-success" onClick={saveRetiro}>
                {" "}
                {t("Registrar")}
              </Button>

              <Button
                href="/Retiros"
                className="btn btn-danger"
                id="botonListado"
              >
                {t("Regresar")}
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>{t("Progreso")}</h4>
            </ModalHeader>
            <ModalBody>
              Estamos enviando los correos, este proceso puede tardar varios
              minutos.
              <br />
              {t("Por favor no cierre ni refresque su navegador.")}
              <br />
              <div className="divPadre2">
                <div className="divHijo2">
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem",
                      color: "#232B41",
                    }}
                  />
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default RetirosCreate;

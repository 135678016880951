import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function ListadoContenedoresAdmin() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CONTENEDORES = process.env.REACT_APP_URL_CONTENEDORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_GASTOS_CONTENEDOR = process.env.REACT_APP_URL_GASTOS_CONTENEDOR;
  const URL_CARGOS_CONTENEDOR = process.env.REACT_APP_URL_CARGOS_CONTENEDOR;
  const URL_CONCEPTOS_GASTOS = process.env.REACT_APP_URL_CONCEPTOS_GASTOS;

  const [t, i18n] = useTranslation("global");
  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 91);

  let comp30 = new Date();
  comp30.setDate(comp30.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [proveedores, setProveedores] = useState([]);
  const [selectedNaviera, setSelectedNaviera] = useState("");
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedAgente, setSelectedAgente] = useState("");
  const [selectedForwarder, setSelectedForwarder] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPuerto, setSelectedPuerto] = useState("");
  const [selectedAsegurado, setSelectedAsegurado] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [fecha, setFecha] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);


  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);


  const [modalGastos, setModalGastos] = useState(false);
  const toggleGastos = () => setModalGastos(!modalGastos);
  const [fechaGasto, setFechaGasto] = useState(endDate);
  const [totalUSD, setTotalUSD] = useState(0);
  const [tipoCambio, setTipoCambio] = useState(1);
  const [total, setTotal] = useState(0);
  const [descripcion, setDescripcion] = useState("NA");
  const [conceptosGastos, setConceptosGastos] = useState([]);
  const [selectedConcepto, setSelectedConcepto] = useState("");
  const [selectedContenedor, setSelectedContenedor] = useState("");
  const [selectedClienteGasto, setSelectedClienteGasto] = useState("");
  const [numeroInterno, setnumeroInterno] = useState("");
  const [moneda, setMoneda] = useState("MXN");

  const [modalCargos, setModalCargos] = useState(false);
  const toggleCargos = () => setModalCargos(!modalCargos);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      proveedor: "",
      concepto: "",
      descripcion: "NA",
      total: 0,
      totalUSD: 0,
    },
  ]);


  const [inputFieldsCargos, setInputFieldsCargos] = useState([
    {
      id: uuidv4(),
      concepto: "",
      descripcion: "NA",
      total: 0,
      totalUSD: 0,
    },
  ]);

  useMemo(() => {
    axios
      .get(
        `${URL_CONTENEDORES}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allContenedores = response.data;
        // Array para pagination
        let arrayTabla = allContenedores
          .sort((a, b) => (a.idContenedor < b.idContenedor ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idContenedor,
              activo: a.is_active,
              fecha: a.fecha,
              observaciones: a.observaciones,
              totalGeneral: a.totalGeneral,
              navieras: a.navieras[0].name,
              idNavieras: a.navieras[0]._id,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              agentes: a.agentes[0].name,
              idAgentes: a.agentes[0]._id,
              terminales: a.terminales[0].name,
              idTerminales: a.terminales[0]._id,
              programas: a.programas[0].name,
              idProgramas: a.programas[0]._id,
              forwarders: a.forwarders[0].name,
              idForwarders: a.forwarders[0]._id,
              totalCantidad: a.totalCantidad,
              numeroInterno: a.clientes[0].nombre_comercial + "-" + a.idContenedor,
              contenedor: a.contenedor,
              // etaEstimada: a.etaEstimada,
              etaReal: a.etaReal,
              bl: a.bl,
              pl: a.pl,
              totalGastos: a.totalGastos,
              totalCargos: a.totalCargos,
              puerto: a.puerto,
              direccionEntrega: a.direccionEntrega,
              totalCargos: a.totalCargos,

              pedimento: a.pedimento,
              modalidad: a.modalidad,

              asegurado: a.asegurado,

              factura: a.factura,
              documentos: a.documentos,
              telex: a.telex,
              toquePiso: a.toquePiso,
              revalidacion: a.revalidacion,
              fichaTecnica: a.fichaTecnica,
              pagoPedimento: a.pagoPedimento,
              modulacion: a.modulacion,
              retornoVacio: a.retornoVacio,
              salidaPuerto: a.salidaPuerto,
              salidaRuta: a.salidaRuta,
              entregaCliente: a.entregaCliente,
              status: a.status,
              fechaEntradaPuerto: a.fechaEntradaPuerto,
              fechaDocumentos: a.fechaDocumentos,
              fechaTelex: a.fechaTelex,
              fechaToquePiso: a.fechaToquePiso,
              fechaRevalidacion: a.fechaRevalidacion,
              fechaFichaTecnica: a.fechaFichaTecnica,
              fechaPagoPedimento: a.fechaPagoPedimento,
              fechaModulacion: a.fechaModulacion,
              fechaRetornoVacio: a.fechaRetornoVacio,
              fechaSalidaPuerto: a.fechaSalidaPuerto,
              fechaSalidaRuta: a.fechaSalidaRuta,
              fechaEntregaCliente: a.fechaEntregaCliente,
              fechaFactura: a.fechaFactura,
              fechaBl: a.fechaBl,
              fechaPl: a.fechaPl,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CLIENTES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });


    axios
      .get(`${URL_PROVEEDORES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });



    axios
      .get(URL_CONCEPTOS_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedNaviera == 0 || selectedNaviera == a.idNavieras) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedForwarder == 0 || selectedForwarder == a.idForwarders) &&
        (selectedStatus == 0 || selectedStatus == a.status) &&
        (selectedPuerto == 0 || selectedPuerto == a.puerto) &&
        (selectedAsegurado == 0 || selectedAsegurado == a.selectedAsegurado) &&
        (selectedAgente == 0 || selectedAgente == a.idAgentes)
      ) {
        return [
          a.numero,
          a.fecha,
          a.etaReal,
          a.numeroInterno,
          a.contenedor,
          a.navieras,
          a.clientes,
          a.agentes,
          a.forwarders,
          a.puerto,
          a.terminal,
          a.modalidad,
          a.fechaSalidaPuerto,
          a.fechaSalidaRuta,
          a.status,
          a.observaciones,
          a.programa,
          a.totalCantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.totalGeneral),
          a.direccionEntrega
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 65, 28);
    doc.text(`Listado Contenedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "ETA",
          "No. Interno",
          "Contenedor",
          "Naviera",
          "Cliente",
          "Agente Aduanal",
          "Forwarder",
          "Puerto",
          "Terminal",
          "Modalidad",
          "Salida Puerto",
          "Salida Ruta",
          "Status",
          "Observaciones",
          "Programa",
          "Cantidad",
          "Total",
          "Direccion Entrega"
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Contenedores.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedNaviera == 0 || selectedNaviera == a.idNavieras) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedForwarder == 0 || selectedForwarder == a.idForwarders) &&
        (selectedStatus == 0 || selectedStatus == a.status) &&
        (selectedPuerto == 0 || selectedPuerto == a.puerto) &&
        (selectedAsegurado == 0 || selectedAsegurado == a.selectedAsegurado) &&
        (selectedAgente == 0 || selectedAgente == a.idAgentes)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          ETA: a.etaReal,
          NumeroInterno: a.numeroInterno,
          Contenedor: a.contenedor,
          Naviera: a.navieras,
          Cliente: a.clientes,
          AgenteAduanal: a.agentes,
          Forwarder: a.forwarders,
          Puerto: a.puerto,
          Terminal: a.terminal,
          Modalidad: a.modalidad,
          FechaSalidaPuerto: a.fechaSalidaPuerto,
          FechaSalidaRuta: a.fechaSalidaRuta,
          FechaEntregaCliente: a.fechaEntregaCliente,
          Asegurado: a.asegurado,
          Status: a.status,
          Observaciones: a.observaciones,
          Programa: a.programa,
          DireccionEntrega: a.direccionEntrega
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoContenedores";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoContenedores",
        sheetFilter: [
          "Numero",
          "Fecha",
          "ETA",
          "NumeroInterno",
          "Contenedor",
          "Naviera",
          "Cliente",
          "AgenteAduanal",
          "Forwarder",
          "Puerto",
          "Terminal",
          "Modalidad",
          "FechaSalidaPuerto",
          "FechaSalidaRuta",
          "FechaEntregaCliente",
          "Asegurado",
          "Status",
          "Observaciones",
          "Programa",
          "DireccionEntrega",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "ETA",
          "NumeroInterno",
          "Contenedor",
          "Naviera",
          "Cliente",
          "AgenteAduanal",
          "Forwarder",
          "Puerto",
          "Terminal",
          "Modalidad",
          "FechaSalidaPuerto",
          "FechaSalidaRuta",
          "FechaEntregaCliente",
          "Asegurado",
          "Status",
          "Observaciones",
          "Programa",
          "DireccionEntrega",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedNaviera == 0 || selectedNaviera == a.idNavieras) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedForwarder == 0 || selectedForwarder == a.idForwarders) &&
        (selectedStatus == 0 || selectedStatus == a.status) &&
        (selectedPuerto == 0 || selectedPuerto == a.puerto) &&
        (selectedAsegurado == 0 || selectedAsegurado == a.selectedAsegurado) &&
        (selectedAgente == 0 || selectedAgente == a.idAgentes)
      ) {
        return [
          a.numero,
          a.fecha,
          a.etaReal,
          a.numeroInterno,
          a.contenedor,
          a.navieras,
          a.clientes,
          a.agentes,
          a.forwarders,
          a.puerto,
          a.terminal,
          a.modalidad,
          a.fechaSalidaPuerto,
          a.fechaSalidaRuta,
          a.status,
          a.observaciones,
          a.programa,
          a.totalCantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.totalGeneral),
          a.direccionEntrega
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 65, 28);
    doc.text(`Listado Contenedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "ETA",
          "No. Interno",
          "Contenedor",
          "Naviera",
          "Cliente",
          "Agente Aduanal",
          "Forwarder",
          "Puerto",
          "Terminal",
          "Modalidad",
          "Salida Puerto",
          "Salida Ruta",
          "Status",
          "Observaciones",
          "Programa",
          "Cantidad",
          "Total",
          "Direccion Entrega"
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Contenedores",
          email: mailTo,
          fileName: "ListadoContenedores.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Contenedores.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }



  const headers = [
    { name: `${t("Numero")}`, field: "numero", sortable: true },
    { name: `${t("Fecha")}`, field: "fecha", sortable: true },
    { name: `${t("ETA")}`, field: "etaReal", sortable: true },
    { name: `${t("No. Interno")}`, field: "numeroInterno", sortable: true },
    { name: `${t("Contenedor")}`, field: "contenedor", sortable: true },
    { name: `${t("Naviera")}`, field: "navieras", sortable: true },
    { name: `${t("Cliente")}`, field: "clientes", sortable: true },
    { name: `${t("Agente Aduanal")}`, field: "agentes", sortable: true },
    { name: `${t("Forwarder")}`, field: "forwarders", sortable: true },
    { name: `${t("Puerto")}`, field: "puerto", sortable: true },
    { name: `${t("Modalidad")}`, field: "modalidad", sortable: true },
    { name: `${t("Asegurado")}`, field: "asegurado", sortable: true },
    // { name: `${t("Salida Puerto")}`, field: "fechaSalidaPuerto", sortable: false },
    // { name: `${t("Entrega Cliente")}`, field: "fechaEntregaCliente", sortable: false },
    { name: `${t("Status")}`, field: "status", sortable: true },
    { name: `${t("Acciones")}`, field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.numeroInterno.toLowerCase().includes(search.toLowerCase()) ||
          comment.contenedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.navieras.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.agentes.toLowerCase().includes(search.toLowerCase()) ||
          comment.status.toLowerCase().includes(search.toLowerCase()) ||
          comment.forwarders.toLowerCase().includes(search.toLowerCase()) ||
          comment.totalCantidad.toString().includes(search) ||
          comment.totalGeneral.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedNaviera) {
      computedComments = computedComments.filter((e) =>
        e.idNavieras.includes(selectedNaviera)
      );
    }

    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }

    if (selectedAgente) {
      computedComments = computedComments.filter((e) =>
        e.idAgentes.includes(selectedAgente)
      );
    }

    if (selectedForwarder) {
      computedComments = computedComments.filter((e) =>
        e.idForwarders.includes(selectedForwarder)
      );
    }

    if (selectedPuerto) {
      computedComments = computedComments.filter((e) =>
        e.puerto.includes(selectedPuerto)
      );
    }
    
    if (selectedAsegurado) {
      computedComments = computedComments.filter((e) =>
        e.asegurado.includes(selectedAsegurado)
      );
    }

    if (selectedStatus) {
      computedComments = computedComments.filter((e) =>
        e.status.includes(selectedStatus)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "totalCantidad" &&
      sorting.field != "totalGeneral" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field == "totalGeneral" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field == "totalGeneral" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedNaviera,
    selectedCliente,
    selectedAgente,
    selectedForwarder,
    selectedPuerto,
    selectedAsegurado,
    selectedStatus,
  ]);


  function saveGasto () {
    setValidaBoton(false);
    toggleProgreso();
    let totalMails = inputFields.length

inputFields.map((a)=>{
  
  axios
  .post(
    URL_GASTOS_CONTENEDOR,
    {
      fecha: fechaGasto,
      totalUSD: a.totalUSD,
      tipoCambio,
      total: a.total,
      abonos: 0,
      saldo: a.total,
      descripcion: a.descripcion,
      conceptosGastos: a.concepto,
      contenedores: selectedContenedor,
      proveedores: a.proveedor,
      clientes: selectedClienteGasto,
      moneda,
    },
    {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    }
  )
  .then(() => {
    totalMails = totalMails - 1
    if(totalMails == 0){
    axios.post(
      URL_LOGS,
      {
        tipo: "Crear Gasto",
        detalle: `Fecha: ${fechaGasto} / Contenedor: ${selectedContenedor}`,
        user: user.id,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    );
    Swal.fire("Good job!", "Creado con exito", "success");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    }
  })
  .catch((error) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
    console.log(error);
    setValidaBoton(true);
  });
})
  };

  function saveCargo () {
    setValidaBoton(false);
    toggleProgreso();
    let totalMails = inputFieldsCargos.length

inputFieldsCargos.map((a)=>{
    axios
      .post(
        URL_CARGOS_CONTENEDOR,
        {
          fecha: fechaGasto,
          totalUSD: a.totalUSD,
          tipoCambio,
          total: a.total,
          abonos: 0,
          saldo: a.total,
          descripcion: a.descripcion,
          conceptosGastos: a.concepto,
          contenedores: selectedContenedor,
          clientes: selectedClienteGasto,
          moneda,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        totalMails = totalMails - 1
        if(totalMails == 0){
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Cargo",
            detalle: `Fecha: ${fecha} / Concepto: ${selectedConcepto} / Total: ${total} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      });
    })
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleChangeInputImporte = (id, value) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
          i.total = parseFloat(value) * tipoCambio;
          i.totalUSD = parseFloat(value);
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  function cambiaMoneda(){
    const newInputFields = inputFields.map((i) => {
          i.total = i.totalUSD
      return i;
    });
    setInputFields(newInputFields);
  }

  function cambiaTipoCambio(e){
    const newInputFields = inputFields.map((i) => {
          i.total = i.totalUSD * e
      return i;
    });
    setInputFields(newInputFields);
  }


  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        proveedor: "",
        concepto: "",
        descripcion: "NA",
        total: 0,
        totalUSD: 0,
      },
    ]);
  };

  // Cargos

  const handleChangeInputCargos = (id, event) => {
    const newInputFieldsCargos = inputFieldsCargos.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsCargos(newInputFieldsCargos);
  };

  const handleChangeInputImporteCargos = (id, value) => {
    const newInputFieldsCargos = inputFieldsCargos.map((i) => {
      if (id === i.id) {
          i.total = parseFloat(value) * tipoCambio;
          i.totalUSD = parseFloat(value);
      }
      return i;
    });
    setInputFieldsCargos(newInputFieldsCargos);
  };

  function cambiaMonedaCargos(){
    const newInputFieldsCargos = inputFieldsCargos.map((i) => {
          i.total = i.totalUSD
      return i;
    });
    setInputFieldsCargos(newInputFieldsCargos);
  }

  function cambiaTipoCambioCargos(e){
    const newInputFieldsCargos = inputFieldsCargos.map((i) => {
          i.total = i.totalUSD * e
      return i;
    });
    setInputFieldsCargos(newInputFieldsCargos);
  }


  const handleRemoveFieldsCargos = (id) => {
    const values = [...inputFieldsCargos];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsCargos(values);
  };

  const handleAddFieldsCargos = () => {
    setInputFieldsCargos([
      ...inputFieldsCargos,
      {
        id: uuidv4(),
        proveedor: "",
        concepto: "",
        descripcion: "NA",
        total: 0,
        totalUSD: 0,
      },
    ]);
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menuContenedores ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                {t("Regresar")}
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>{t("Enviar Listado Contenedores")}</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                {t("Enviar")}
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">{t("Contenedores")}</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Inicio")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Fin")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedNaviera}
                  onChange={(e) => {
                    setSelectedNaviera(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">{t("Selecciona")}</option>
                  {proveedores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (a.tipo === "Naviera") {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCliente}
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">{t("Selecciona")}</option>
                  {clientes
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAgente}
                  onChange={(e) => {
                    setSelectedAgente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">{t("Selecciona")}</option>
                  {proveedores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (a.tipo === "Agente") {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedForwarder}
                  onChange={(e) => {
                    setSelectedForwarder(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">{t("Selecciona")}</option>
                  {proveedores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (a.tipo === "Forwarder") {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>  
              <Input
                  bsSize="sm"
                  type="select"
                  placeholder="Puerto"
                  value={selectedPuerto}
                  required
                  onChange={(e) => {
                    setSelectedPuerto(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Aeropuero CDMX">Aeropuero CDMX</option>
                  <option value="Laredo">Laredo</option>
                  <option value="Lazaro Cardenas">Lazaro Cardenas</option>
                  <option value="Manzanillo">Manzanillo</option>
                  <option value="Progreso">Progreso</option>
                  <option value="Tijuana">Tijuana</option>
                  <option value="Veracruz">Veracruz</option>
                </Input></td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                type="select"
                placeholder="Asegurado"
                value={selectedAsegurado}
                required
                onChange={(e) => {
                  setSelectedAsegurado(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                <option value="No">No</option>
                <option value="Si">Si</option>
                <option value="No Aplica">No Aplica</option>
              </Input>
              </td>
              {/* <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td> */}
              <td style={{ paddingTop: "0px" }}>
                <Input
                  type="select"
                  bsSize="sm"
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Pendiente">Pendiente</option>
                  <option value="BL">BL</option>
                  <option value="PL">PL</option>
                  <option value="Factura">Factura</option>
                  <option value="Ficha Tecnica">Ficha Tecnica</option>
                  <option value="Toque Piso">Toque Piso</option>
                  <option value="Telex"> Telex</option>
                  <option value="Naviera Freno Demoras">Naviera Freno Demoras</option>
                  <option value="Revalidacion">Revalidacion</option>
                  <option value="Pago Pedimento">Pago Pedimento</option>
                  <option value="Por Modular">Por Modular</option>
                  <option value="Modulado">Modulado</option>
                  <option value="Por Documentar Tren">Por Documentar Tren</option>
                  <option value="Por subir Tren">Por subir Tren</option>
                  <option value="Maniobra Transportista">Maniobra Transportista</option>
                  <option value="Maniobra Racs">Maniobra Racs</option>
                  <option value="Maniobra Conia">Maniobra Conia</option>
                  <option value="Patio Racs">Patio Racs</option>
                  <option value="Patio Conia">Patio Conia</option>
                  <option value="Patio Transportista">Patio Transportista</option>
                  <option value="Entregado">Entregado</option>
                  <option value="Cancelado">Cancelado</option>
                </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                return (
                  <tr>
                    <td>{a.numero}</td>
                    <td>{a.fecha}</td>
                    <td>{a.etaReal}</td>
                    <td>{a.numeroInterno}</td>
                    <td>{a.contenedor}</td>
                    <td>{a.navieras}</td>
                    <td>{a.clientes}</td>
                    <td>{a.agentes}</td>
                    <td>{a.forwarders}</td>
                    <td>{a.puerto}</td>
                    <td>{a.modalidad}</td>
                    <td>{a.asegurado}</td>
                    {/* <td>{a.fechaSalidaPuerto}</td>
                    <td>{a.fechaEntregaCliente}</td> */}
                    <td>{a.status}</td>

                    <td>
                    
                      <Button
                        color="primary"
                        id="Editar"
                        size="sm"
                        onClick={(e) => {
                          setSelectedContenedor(a._id);
                          setSelectedClienteGasto(a.idClientes);
                          setnumeroInterno(a.numeroInterno);
                          toggleGastos();
                        }}
                      >
                        Gastos
                      </Button>
                      <Button
                        color="info"
                        id="Editar"
                        size="sm"
                        onClick={(e) => {
                          setSelectedContenedor(a._id);
                          setSelectedClienteGasto(a.idClientes);
                          setnumeroInterno(a.numeroInterno);
                          toggleCargos();
                        }}
                      >
                        Cargos
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>{t("Progreso")}</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            {t("Este proceso puede tardar varios segundos.")}
            <br />
            {t("Por favor no cierre ni refresque su navegador.")}
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>


      <Modal size="xl" isOpen={modalGastos} toggle={toggleGastos}>
        <ModalHeader toggle={toggleGastos}>
          <h4>
            {t("Gastos")} Contenedor {numeroInterno}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
          <Col md={6}>
                    <Label className="mr-sm-2">
                      {t("Fecha")}
                    </Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fechaGasto}
                      required
                      onChange={(e) => {
                        setFechaGasto(e.target.value);
                      }}
                    />
                  </Col>
                  
            <Col md={6}>
              <Label className="mr-sm-2">{t("Cliente")}</Label>
              <Input
                // bsSize="sm"
                type="select"
                required
                value={selectedClienteGasto}
                disabled
              >
                <option value="0">{t("Selecciona")}</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className="mr-sm-2">{t("Moneda")}</Label>
              <Input
                // bsSize="sm"
                type="select"
                required
                value={moneda}
                onChange={(e) => {
                  setMoneda(e.target.value);
                  setTipoCambio(1);
                  cambiaMoneda();
                }}
              >
                <option value="MXN">{t("MXN")}</option>
                <option value="USD">{t("USD")}</option>
              </Input>
            </Col>
            {moneda === "USD" ? (
              <Col md={6}>
                <Label className="mr-sm-2">{t("Tipo de Cambio")}</Label>
                <Input
                  // bsSize="sm"
                  type="number"
                  required
                  value={tipoCambio}
                  onChange={(e) => {
                    setTipoCambio(e.target.value);
                    cambiaTipoCambio(e.target.value);
                  }}
                />
              </Col>
            ) : undefined}
          </Row>
          <br />

          <Row>
              <Col md={2}>
                <Label className="mr-sm-2">{t("Proveedor")}</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">{t("Concepto")}</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Descripcion</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Importe</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      // bsSize="sm"
                      name="proveedor"
                      type="select"
                      value={inputField.proveedor}
                      required
                      onChange={(event) => {
                        handleChangeInput(inputField.id, event);
                      }}
                    >
                      <option value="0">{t("Selecciona")}</option>
                      {proveedores
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.name}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col md={2}>
                    <Input
                      // bsSize="sm"
                      name="concepto"
                      type="select"
                      value={inputField.concepto}
                      required
                      onChange={(event) => {
                        handleChangeInput(inputField.id, event);
                      }}
                    >
                      <option value="0">{t("Selecciona")}</option>
                      {conceptosGastos
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.name}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col md={2}>
                    <Input
                      // bsSize="sm"
                      name="descripcion"
                      type="text"
                      placeholder="descripcion"
                      value={inputField.descripcion}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={2}>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={inputField.importe}
                      className={`form-control`}
                      onValueChange={(value, name) => {
                        handleChangeInputImporte(inputField.id, value)
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
          <br />
          {validaBoton?(
            <Button
                color="success"
                onClick={() =>saveGasto()}>
                Guardar
              </Button>
          ):(
              <Button
                color="success"
                disabled>
                Guardar
              </Button>
          )}
          
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalCargos} toggle={toggleCargos}>
        <ModalHeader toggle={toggleCargos}>
          <h4>
            {t("Cargos")} Contenedor {numeroInterno}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
          <Col md={6}>
                    <Label className="mr-sm-2">
                      {t("Fecha")}
                    </Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fechaGasto}
                      required
                      onChange={(e) => {
                        setFechaGasto(e.target.value);
                      }}
                    />
                  </Col>
           

            <Col md={6}>
              <Label className="mr-sm-2">{t("Cliente")}</Label>
              <Input
                // bsSize="sm"
                type="select"
                required
                value={selectedClienteGasto}
                disabled
              >
                <option value="0">{t("Selecciona")}</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Label className="mr-sm-2">{t("Moneda")}</Label>
              <Input
                // bsSize="sm"
                type="select"
                required
                value={moneda}
                onChange={(e) => {
                  setMoneda(e.target.value);
                  setTipoCambio(1);
                  cambiaMonedaCargos();
                }}
              >
                <option value="MXN">{t("MXN")}</option>
                <option value="USD">{t("USD")}</option>
              </Input>
            </Col>
            {moneda === "USD" ? (
              <Col md={6}>
                <Label className="mr-sm-2">{t("Tipo de Cambio")}</Label>
                <Input
                  // bsSize="sm"
                  type="number"
                  required
                  value={tipoCambio}
                  onChange={(e) => {
                    setTipoCambio(e.target.value);
                    cambiaTipoCambioCargos(e.target.value);
                  }}
                />
              </Col>
            ) : undefined}
          </Row>
          <br />

          <Row>
              <Col md={2}>
                <Label className="mr-sm-2">{t("Concepto")}</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Descripcion</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Importe</Label>
              </Col>
            </Row>

            {inputFieldsCargos.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                
                  <Col md={2}>
                    <Input
                      // bsSize="sm"
                      name="concepto"
                      type="select"
                      value={inputField.concepto}
                      required
                      onChange={(event) => {
                        handleChangeInputCargos(inputField.id, event);
                      }}
                    >
                      <option value="0">{t("Selecciona")}</option>
                      {conceptosGastos
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.name}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col md={2}>
                    <Input
                      // bsSize="sm"
                      name="descripcion"
                      type="text"
                      placeholder="descripcion"
                      value={inputField.descripcion}
                      required
                      onChange={(event) =>
                        handleChangeInputCargos(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={2}>

                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={inputField.importe}
                      className={`form-control`}
                      onValueChange={(value, name) => {
                        handleChangeInputImporteCargos(inputField.id, value)
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFieldsCargos(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFieldsCargos}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
          <br />
          {validaBoton?(
            <Button
                color="success"
                onClick={() =>saveCargo()}>
                Guardar
              </Button>
          ):(
              <Button
                color="success"
                disabled>
                Guardar
              </Button>
          )}
        </ModalBody>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoContenedoresAdmin;
